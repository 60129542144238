<template>
  <div class="app-credentials-upsert">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:toolbar> </template>
      <template v-slot:preview>
        <b-row>
          <b-col cols="4">
            <div>
              <b-form-group>
                <label class="required-control"> Tên ứng dụng: </label>
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="dataModel.name"
                  placeholder="Tên ứng dụng"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="required-control"> ClientId: </label>

                <b-input-group>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    v-model="dataModel.clientId"
                    placeholder="ClientId"
                    autocomplete="off"
                    :disabled="true"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="secondary"
                      v-clipboard:copy="dataModel.clientId"
                      title="Mã client"
                      @click="makeToastSuccess('Đã copy clientId')"
                      ><i class="flaticon2-checking"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <label class="required-control"> ClientSecret: </label>

                <b-input-group>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    v-model="dataModel.clientSecret"
                    placeholder="clientSecret"
                    autocomplete="off"
                    :disabled="true"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="secondary"
                      v-clipboard:copy="dataModel.clientSecret"
                      title="Mã clientSecret"
                      @click="makeToastSuccess('Đã copy clientSecret')"
                      ><i class="flaticon2-checking"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <label>RedirectUri:</label>
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="dataModel.redirectUri"
                  placeholder="redirectUri"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label>Token Expired:</label>
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="dataModel.expiresInToken"
                  placeholder="tokenExpired"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>RefreshToken Expired:</label>
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="dataModel.expiresInRefreshToken"
                  placeholder="refreshTokenExpired"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="d-flex d-inline">
                  {{ dataModel.active ? 'Hoạt động' : 'Không hoạt động' }}
                  <b-form-checkbox
                    class="ml-2"
                    v-model="dataModel.active"
                    switch
                  />
                </label>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="4">
                <b-form-group>
                  <label class="required-control"> Đối tác: </label>
                  <Autosuggest
                    :model="dataModel.partnerName"
                    :suggestions="optionPartners"
                    placeholder="đối tác"
                    :limit="10"
                    @select="onSelect($event, 'Partner')"
                    @change="onSearch($event, 'Partner')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label class="required-control d-inline-block">
                    Cấu hình:
                  </label>
                  <b-form-checkbox
                    class="ml-2 d-inline-block"
                    v-model="dataModel.haveConfig"
                    switch
                  />
                  <Autosuggest
                    v-show="dataModel.haveConfig"
                    :model="dataModel.appConfigName"
                    :suggestions="optionAppConfigs"
                    placeholder="cấu hình"
                    :limit="10"
                    @select="onSelect($event, 'AppConfig')"
                    @change="onSearch($event, 'AppConfig')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Chọn quyền:</label>
                <Autosuggest
                  :model="dataModel.roleName"
                  :suggestions="optionRoles"
                  placeholder="quyền"
                  :limit="10"
                  @select="onSelect($event, 'Role')"
                  @change="onSearch($event, 'Role')"
                  :disabled="!isShowHtml(['EMPLOYEE_VIEW'])"
                />
              </b-col>
              <b-col cols="4" class="d-flex align-items-end">
                <b-button
                  class="font-weight-bolder"
                  size="sm"
                  variant="secondary"
                  style="width: 80px"
                  @click="onClickViewRole"
                  :disabled="!isShowHtml(['EMPLOYEE_VIEW'])"
                >
                  <span>Xem</span></b-button
                >
              </b-col>
            </b-row>
            <hr
              class="hr-text"
              data-content="Quản lí Access Token"
              style="font-weight: 600"
            />
            <div>
              <b-table
                :items="accessTokens"
                :fields="fieldTables"
                :small="true"
                :busy="onLoading"
                responsive
                bordered
                hover
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="10"
                  ></vcl-table>
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(token)="row">
                  <span
                    :title="row.item.token"
                    v-if="row.item.token"
                    class="d-flex d-block"
                  >
                    {{ row.item.token ? row.item.token.substring(0, 20) : '' }}
                  </span>
                  <b class="d-flex d-block">{{ row.item.tokenType }}</b>
                </template>
                <template #cell(count)="row">
                  <b>{{ row.item.count }} Lần</b>
                </template>
                <template #cell(deleted)="row">
                  <span
                    v-if="!row.item.deleted"
                    v-text="'Hoạt động'"
                    class="label font-weight-bold label-lg label-light-success label-inline"
                  ></span>
                  <span
                    v-else
                    v-text="'Thu hồi'"
                    class="label font-weight-bold label-lg label-light-danger label-inline"
                  ></span>
                </template>
                <template #cell(actions)="row">
                  <b-dropdown size="sm" no-caret right v-if="!row.item.deleted">
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="onRemoveAccessToken(row.item.id)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon-download-1"
                        ></i>
                        &nbsp; Thu hồi
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số :
                  {{ convertPrice(pageConfigAccessToken.totalRows) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination
                  pills
                  v-if="pageConfigAccessToken.numberPages > 1"
                  v-model="pageConfigAccessToken.currentPage"
                  :total-rows="pageConfigAccessToken.totalRows"
                  :per-page="PER_PAGE_TOKEN"
                  align="right"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
            <hr
              class="hr-text"
              data-content="Quản lí Refresh Token"
              style="font-weight: 600"
            />
            <div>
              <b-table
                :items="refreshTokens"
                :fields="fieldTables"
                :small="true"
                :busy="onLoading"
                responsive
                bordered
                hover
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="10"
                  ></vcl-table>
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(token)="row">
                  <span
                    :title="row.item.token"
                    v-if="row.item.token"
                    class="d-flex d-block"
                  >
                    {{ row.item.token ? row.item.token.substring(0, 20) : '' }}
                  </span>
                  <b class="d-flex d-block">{{ row.item.tokenType }}</b>
                </template>
                <template #cell(count)="row">
                  <b class="d-block">{{ row.item.count }} Lần</b>
                  <small class="text-danger d-block" v-if="row.item.count > 1">
                    Leak refresh Token
                  </small>
                </template>
                <template #cell(deleted)="row">
                  <span
                    v-if="!row.item.deleted"
                    v-text="'Hoạt động'"
                    class="label font-weight-bold label-lg label-light-success label-inline"
                  ></span>
                  <span
                    v-else
                    v-text="'Thu hồi'"
                    class="label font-weight-bold label-lg label-light-danger label-inline"
                  ></span>
                </template>
                <template #cell(actions)="row">
                  <b-dropdown size="sm" no-caret right v-if="!row.item.deleted">
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="onRemoveRefreshToken(row.item.id)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon-download-1"
                        ></i>
                        &nbsp; Thu hồi
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số :
                  {{ convertPrice(pageConfigRefreshToken.totalRows) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination
                  pills
                  v-if="pageConfigRefreshToken.numberPages > 1"
                  v-model="pageConfigRefreshToken.currentPage"
                  :total-rows="pageConfigRefreshToken.totalRows"
                  :per-page="PER_PAGE_TOKEN"
                  aria-controls="my-table"
                  align="right"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-button
          class="font-weight-bolder mr-4"
          size="sm"
          variant="primary"
          style="width: 80px"
          @click="upsertData"
        >
          <span>{{ id ? 'Cập nhật' : 'Tạo mới' }}</span></b-button
        >
      </template>
    </KTCodePreview>
    <b-modal
      ref="modal-permission"
      hide-footer
      title=""
      id="modal-permission"
      size="xl"
    >
      <UpdatePermission :id="dataModel.roleId" />
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice } from '@/utils/common';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
  cloneDeep,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import UpdatePermission from '@/view/pages/permissions/UpdatePermission.vue';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { v4 } from 'uuid';

const PER_PAGE_TOKEN = 5;
export default {
  data() {
    return {
      PER_PAGE_TOKEN: PER_PAGE_TOKEN,
      pageTitle: '',
      dataModel: {
        roleName: '',
        partnerName: '',
      },
      onLoading: false,
      id: null,
      fieldTables: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'token',
          label: 'Token',
          sortable: false,
          thStyle: { width: '13%' },
        },
        {
          key: 'count',
          label: 'Truy cập',
          sortable: false,
          thStyle: { textAlign: 'center', width: '10%' },
          tdClass: 'text-center',
        },
        {
          key: 'expiresIn',
          label: 'ExpiresIn',
          sortable: false,
          thStyle: { width: '8%' },
        },
        {
          key: 'expiresOn',
          label: 'ExpiresOn',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'deleted',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      accessTokens: [],
      refreshTokens: [],
      optionOrigin: {},
      optionRoles: [],
      optionPartners: [],
      optionAppConfigs: [],
      pageConfig: {
        currentPage: 1,
        numberPages: 1,
        totalRows: 0,
      },
      pageConfigAccessToken: {
        ...this.pageConfig,
        currentPage: 1,
      },
      pageConfigRefreshToken: {
        ...this.pageConfig,
        currentPage: 1,
      },
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
    UpdatePermission,
  },
  computed: {},
  watch: {
    'pageConfigAccessToken.currentPage': {
      handler() {
        this.fetchAccessTokens(this.id);
      },
    },
    'pageConfigRefreshToken.currentPage': {
      handler() {
        this.fetchRefreshTokens(this.id);
      },
    },
  },
  mounted() {
    this.pageTitle = 'Cập nhật Credentials';
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    this.id = this.$route.params.id || null;
    if (this.id) {
      this.fetchDataById(this.id);
      this.fetchAccessTokens(this.id);
      this.fetchRefreshTokens(this.id);
    } else {
      this.dataModel.clientId = v4();
      this.dataModel.clientSecret = v4();
    }
    this.fetchRoles();
    this.fetchPartners();
    this.fetchApplications();
  },
  methods: {
    convertPrice,
    makeToastSuccess,
    isShowHtml(conditions) {
      return checkPermissions(conditions);
    },
    onSelect(option, type) {
      switch (type) {
        case 'Role': {
          this.dataModel.roleId = option.item.id;
          break;
        }
        case 'Partner': {
          this.dataModel.partnerId = option.item.id;
          break;
        }
        case 'AppConfig': {
          this.dataModel.appConfigId = option.item.id;
          break;
        }
      }
    },
    onSearch(textInput, type) {
      switch (type) {
        case 'Role': {
          this.optionRoles = this.fitlerOptionsBy(
            this.optionOrigin.roles,
            textInput,
            'name',
            10,
          );
          break;
        }
        case 'Partner': {
          this.optionPartners = this.fitlerOptionsBy(
            this.optionOrigin.partners,
            textInput,
            'name',
            10,
          );
          break;
        }
        case 'AppConfig': {
          this.optionAppConfigs = this.fitlerOptionsBy(
            this.optionOrigin.appConfigs,
            textInput,
            'name',
            10,
          );
          break;
        }
      }
    },
    findAndUpdateDeletedToken(items, id) {
      const index = items.findIndex((item) => item.id === id);
      if (index > -1) {
        items[index].deleted = true;
      }
    },
    onRemoveAccessToken(accessTokenId) {
      ApiService.delete(`/oauth2-access-tokens/${accessTokenId}`).then(
        (response) => {
          const { data, message } = response.data;
          this.findAndUpdateDeletedToken(this.accessTokens, data);
          makeToastSuccess(message);
        },
      );
    },
    onRemoveRefreshToken(refreshTokenId) {
      ApiService.delete(`/oauth2-refresh-tokens/${refreshTokenId}`).then(
        (response) => {
          const { data, message } = response.data;
          this.findAndUpdateDeletedToken(this.refreshTokens, data);
          makeToastSuccess(message);
        },
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchDataById(id) {
      ApiService.get(`/app-credentials/${id}`).then((response) => {
        const { data } = response.data;
        this.dataModel = data;
      });
    },
    upsertData() {
      const method = this.id ? 'put' : 'post';
      ApiService[method]('/app-credentials', this.dataModel)
        .then(() => {
          makeToastSuccess('Thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    fetchAccessTokens(appId) {
      const params = {
        appId: appId,
        page: this.pageConfigAccessToken.currentPage,
        size: PER_PAGE_TOKEN,
      };
      ApiService.query('/oauth2-access-tokens', { params }).then((response) => {
        const { data, total_page, total } = response.data.data;
        this.pageConfigAccessToken.numberPages = total_page;
        this.pageConfigAccessToken.totalRows = total;
        this.accessTokens = data;
      });
    },
    fetchRefreshTokens(appId) {
      const params = {
        appId: appId,
        page: this.pageConfigRefreshToken.currentPage,
        size: PER_PAGE_TOKEN,
      };
      ApiService.query('/oauth2-refresh-tokens', { params }).then(
        (response) => {
          const { data, total_page, total } = response.data.data;
          this.pageConfigRefreshToken.numberPages = total_page;
          this.pageConfigRefreshToken.totalRows = total;
          this.refreshTokens = data;
        },
      );
    },
    fetchRoles() {
      ApiService.query('role', {
        params: {
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        const { list_role } = response.data.data;
        this.optionRoles = list_role;
        this.optionOrigin.roles = list_role;
      });
    },
    fetchPartners() {
      ApiService.query('partners').then((response) => {
        const { data } = response.data.data;
        this.optionPartners = data;
        this.optionOrigin.partners = data;
      });
    },
    fetchApplications() {
      ApiService.query('partner-apis').then((response) => {
        const { data } = response.data.data;
        this.optionAppConfigs = data;
        this.optionOrigin.applications = data;
      });
    },
    onClickViewRole() {
      this.$bvModal.show('modal-permission');
    },
  },
};
</script>
